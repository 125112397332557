import React from 'react';

import './app.css';

const Styles = () => {
    return (
        <></>
    );
};

export default Styles;